import BookIcon from '@material-ui/icons/Book';
import ComputerIcon from '@material-ui/icons/Computer';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SvgIcon from '@material-ui/core/SvgIcon';

function FantasyIcon() {
    return <svg style={{width: "65%", height: "65%"}} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24">
        <path fill="#1e88e5"
              d="M1 21V9h2v2h2V3h2v2h2V3h2v2h2V3h2v2h2V3h2v8h2V9h2v12h-9v-3q0-.825-.587-1.413Q12.825 16 12 16q-.825 0-1.412.587Q10 17.175 10 18v3Zm8-9h2V9H9Zm4 0h2V9h-2Z"/>
    </svg>
}

function PotterIcon() {
    return <svg style={{width: "65%", height: "65%"}} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                viewBox="0 0 24 24" width="24px" fill="#1e88e5">
        <g>
            <rect fill="none" height="24" width="24" x="0"/>
        </g>
        <g>
            <g>
                <polygon points="20,7 20.94,4.94 23,4 20.94,3.06 20,1 19.06,3.06 17,4 19.06,4.94"/>
                <path
                    d="M17.71,9.12l-2.83-2.83C14.68,6.1,14.43,6,14.17,6c-0.26,0-0.51,0.1-0.71,0.29L2.29,17.46c-0.39,0.39-0.39,1.02,0,1.41 l2.83,2.83C5.32,21.9,5.57,22,5.83,22s0.51-0.1,0.71-0.29l11.17-11.17C18.1,10.15,18.1,9.51,17.71,9.12z M14.17,8.42l1.41,1.41 L14.41,11L13,9.59L14.17,8.42z M5.83,19.59l-1.41-1.41L11.59,11L13,12.41L5.83,19.59z"/>
            </g>
        </g>
    </svg>
}

function RomanceIcon(){
    return <svg style={{width: "65%", height: "100%"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="m148.97 22.47l-6.25.093l-2.564 6.156c-13.235 37.556-21.28 79-21.28 118.093c0 53.777 14.848 93.17 39.874 118.875c18.945 19.458 43.36 30.696 70.156 35c17.09 48.115 16.085 101.005-2.562 148.687c-30.555 5.118-60.254 18.273-86.313 39.5h231.22c-26.066-21.23-55.75-34.384-86.313-39.5c-18.667-47.734-19.62-100.686-2.468-148.844c26.58-4.382 50.84-15.552 69.75-34.842c25.184-25.692 40.186-65.08 40.186-118.875c0-39.093-8.045-80.537-21.28-118.094l-2.188-6.25h-219.97zm6.75 18.686h199.843c7.25 21.815 12.64 44.904 15.593 67.72h-231.03c2.953-22.816 8.344-45.905 15.593-67.72zm-17.47 86.406h234.78c.45 6.49.69 12.912.69 19.25c0 50.357-13.716 84.26-34.845 105.813c-21.13 21.554-50.295 31.406-83.53 31.406c-33.238 0-62.247-9.863-83.22-31.405s-34.563-55.437-34.563-105.813c0-6.338.24-12.76.688-19.25z"/></svg>

}


function GameOfThronesIcon() {
    return <svg style={{width: "65%", height: "65%"}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
        <path fill="#1e88e5"
              d="M6.92 5H5l9 9l1-.94m4.96 6.06l-.84.84a.996.996 0 0 1-1.41 0l-3.12-3.12l-2.68 2.66l-1.41-1.41l1.42-1.42L3 7.75V3h4.75l8.92 8.92l1.42-1.42l1.41 1.41l-2.67 2.67l3.12 3.12c.4.4.4 1.03.01 1.42Z"/>
    </svg>
}

class Theme {
    name: string;
    displayName: string;
    icon = <BookIcon/>;

    constructor(name: string, displayName: string, icon: any) {
        this.name = name;
        this.displayName = displayName;
        this.icon = icon;
    }
}

const themes = [
    new Theme("scifi", "Sci-Fi", <ComputerIcon/>),
    // new Theme("fantasy", "Fantasy",<SvgIcon component={FantasyIcon}/>),
    new Theme("romance", "Romance", <RomanceIcon/>),
    new Theme("got", "Game of Thrones", <GameOfThronesIcon/>),
    // new Theme("potter", "Harry Potter", <SvgIcon component={PotterIcon}/>),
    new Theme("erotica", "Erotica", <FavoriteIcon/>),
    new Theme("eroticaCustom", "Erotica Custom", <FavoriteBorderIcon/>),
];

export {themes, Theme};