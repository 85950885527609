import React from "react";
import Navbar from "./../components/Navbar";
import "./../util/analytica";
import {AuthProvider} from "./../util/auth";
import {ThemeProvider} from "./../util/theme";
import {QueryClientProvider} from "./../util/db";
import "../css/checkered.css";
import Script from "next/script";
import {useRouter} from 'next/router';


function MyApp({Component, pageProps}) {
    const router = useRouter();
    React.useEffect(() => {
        window.addEventListener('unhandledrejection', handleUnhandledRejection);

        return () => {
            window.removeEventListener('unhandledrejection', handleUnhandledRejection);
        };
    }, []);
    React.useEffect(() => {
        const handleRouteChange = (url) => {
            const urlParams = new URLSearchParams(window.location.search);
            const landingParams = {};

            // Collect all query params
            urlParams.forEach((value, key) => {
                landingParams[key] = value;
            });

            // Check if landingRecorded is true
            const landingRecorded = localStorage.getItem('landingRecorded') === 'true';

            if (!landingRecorded) {
                // Store query params in localStorage
                localStorage.setItem('landingParams', JSON.stringify(landingParams));
                localStorage.setItem('landingRecorded', 'true');
                console.log('recording landing params...', landingParams);
            }
            else{
                console.log('landing params already recorded');
            }
        };


        router.events.on('routeChangeComplete', handleRouteChange);

        // Call handleRouteChange initially in case the page is loaded directly with query params
        handleRouteChange(router.asPath);


        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events, router.asPath]);

    const handleUnhandledRejection = (event) => {
        if (event.reason && event.reason.message && event.reason.message.includes("Failed to fetch")) {
            console.warn("Suppressed error:", event.reason);
            event.preventDefault();  // This suppresses the error from being thrown in the console
        }
    };
    return (
        <QueryClientProvider>
            <ThemeProvider>
                <AuthProvider>
                    <Scripts/>

                    <Navbar
                        color="default"
                        logo="https://res.cloudinary.com/dy4s1umzd/image/upload/v1670302661/photobear_site_assets/Quill_and_ink.svg"
                        logoInverted="https://res.cloudinary.com/dy4s1umzd/image/upload/v1670302661/photobear_site_assets/Quill_and_ink.svg"
                    />

                    <Component {...pageProps} />
                    <a style={{display: "none"}}
                        id="verification-aitoolhunt" data-verify-aitoolhunt="115147467733142771703"
                       href="https://www.aitoolhunt.com/tool/mytales.io-theme-erotica?utm_medium=featured&utm_source=mytales.io/theme/erotica"
                       target="_blank"><img width="224" src="https://www.aitoolhunt.com/images/featured-dark.png?a=1"/></a>

                    {/*<Footer*/}
                    {/*    bgColor="light"*/}
                    {/*    size="normal"*/}
                    {/*    bgImage=""*/}
                    {/*    bgImageOpacity={1}*/}
                    {/*    description="A short description of what you do here"*/}
                    {/*    copyright={`© ${new Date().getFullYear()} Hoss Technology Inc.`}*/}
                    {/*    logo="https://res.cloudinary.com/dy4s1umzd/image/upload/e_trim/v1644109377/photobear_site_assets/logos/banner1.png"*/}
                    {/*    logoInverted="https://res.cloudinary.com/dy4s1umzd/image/upload/e_trim/v1644109973/photobear_site_assets/logos/B1-E.png"*/}
                    {/*    sticky={true}*/}
                    {/*/>*/}
                </AuthProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

function Scripts() {
    return (
        <>

            {/* Rudder Stack Analytics */}
            <Script
                dangerouslySetInnerHTML={{
                    __html: '!function(){"use strict";var sdkBaseUrl="https://cdn.rudderlabs.com/v3";var sdkName="rsa.min.js";var asyncScript=true\n' +
                        '        ;window.rudderAnalyticsBuildType="legacy",window.rudderanalytics=[]\n' +
                        '        ;var e=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession"]\n' +
                        '        ;for(var t=0;t<e.length;t++){var n=e[t];window.rudderanalytics[n]=function(e){return function(){\n' +
                        '        window.rudderanalytics.push([e].concat(Array.prototype.slice.call(arguments)))}}(n)}try{\n' +
                        '        new Function(\'return import("")\'),window.rudderAnalyticsBuildType="modern"}catch(a){}\n' +
                        '        if(window.rudderAnalyticsMount=function(){\n' +
                        '        "undefined"==typeof globalThis&&(Object.defineProperty(Object.prototype,"__globalThis_magic__",{get:function get(){\n' +
                        '        return this},configurable:true}),__globalThis_magic__.globalThis=__globalThis_magic__,\n' +
                        '        delete Object.prototype.__globalThis_magic__);var e=document.createElement("script")\n' +
                        '        ;e.src="".concat(sdkBaseUrl,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkName),e.async=asyncScript,\n' +
                        '        document.head?document.head.appendChild(e):document.body.appendChild(e)\n' +
                        '        },"undefined"==typeof Promise||"undefined"==typeof globalThis){var d=document.createElement("script")\n' +
                        '        ;d.src="https://polyfill.io/v3/polyfill.min.js?features=Symbol%2CPromise&callback=rudderAnalyticsMount",\n' +
                        '        d.async=asyncScript,document.head?document.head.appendChild(d):document.body.appendChild(d)}else{\n' +
                        '        window.rudderAnalyticsMount()}window.rudderanalytics.load("2bp0DlJCCS7TxTHyCcAaXQe2I3F","https://hosstechnoujfv.dataplane.rudderstack.com",{})}();',
                }}
            />

            {/* Google Tag Manager */}
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=AW-805456643"
                strategy="afterInteractive"
            />
            <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'AW-805456643');
                    `,
                }}
            />

        </>
    );
}

export default MyApp;
