import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {blue} from '@material-ui/core/colors';
import {Theme, themes} from '../util/story_themes'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    adultOnly: {
        color: 'red'
    },
});

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
    const classes = useStyles();
    const {onClose, selectedValue, open} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Choose story theme</DialogTitle>
            <List>


                {themes.map((theme: Theme) => (
                    <ListItem button onClick={() => handleListItemClick(theme.name)} key={theme.name}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                {theme.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={theme.displayName}
                            secondary={theme.name === 'erotica' || theme.name ==='eroticaCustom' ?
                                <Typography variant="body2" className={classes.adultOnly}>Adult
                                    Only</Typography> : null}
                        />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

export interface ChooseThemeDialogProps {
    open: boolean;
    onClose: () => {};
}

export default function ChooseThemeDialog(props: ChooseThemeDialogProps) {
    // const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    return (
        <div>
            <SimpleDialog selectedValue={selectedValue}
                          open={props.open}
                          onClose={props.onClose}

            />
        </div>
    );
}
