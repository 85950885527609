import React, {useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import Section from "./Section";
import Link from "next/link";
import {useAuth} from "./../util/auth";
import {useDarkMode} from "./../util/theme";
import ChooseThemeDialog from "./ChooseThemeDialog";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {Grid, Typography} from "@material-ui/core";
import {useRouter} from "next/router";

const useStyles = makeStyles((theme) => ({
    logo: {
        height: 28,
        marginRight: theme.spacing(0),
    },
    drawerList: {
        width: 250,
    },
    spacer: {
        flexGrow: 1,
    },
    right: {
        marginLeft: 'auto'
    }
}));


const home = '/'

function Navbar(props) {
    const classes = useStyles();

    const auth = useAuth();
    const darkMode = useDarkMode();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [menuState, setMenuState] = useState(null);

    // Use inverted logo if specified
    // and we are in dark mode
    const logo =
        props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

    const handleOpenMenu = (event, id) => {
        // Store clicked element (to anchor the menu to)
        // and the menu id so we can tell which menu is open.
        setMenuState({anchor: event.currentTarget, id});
    };

    const handleCloseMenu = () => {
        setMenuState(null);
    };

    return (
        <Section bgColor={props.color} size="auto">
            <AppBar position="static" color="transparent" elevation={0}>
                <Container disableGutters={true}>
                    <Toolbar>
                        <Grid container
                              spacing={2}
                              alignItems={'center'}
                              justify={'left'}>
                            <Grid item>
                                <Grid container spacing={0} alignItems={'center'}>
                                    <Link href={home}>
                                        <a>
                                            <img
                                                src={logo}
                                                alt="Logo"
                                                className={classes.logo}
                                            />
                                        </a>
                                    </Link>
                                    <Link href={home}>
                                        <Typography>

                                            <Box fontWeight={'bold'} fontFamily={'Monospace'}
                                            ><a
                                                style={{color: 'black', textDecoration: 'none'}}
                                                href={''}>MyTales</a>
                                            </Box>

                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>

                            <Grid item>
                                {/*<Link href={'/theme/harry-potter?newStory=true'}>*/}
                                <NewStory/>
                                {/*</Link>*/}
                            </Grid>
                            <Grid item className={classes.right}>

                                <Hidden mdUp={true} implementation="css">
                                    <IconButton
                                        onClick={() => {
                                            setDrawerOpen(true);
                                        }}
                                        color="inherit"
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                </Hidden>
                                <Hidden smDown={true} implementation="css">
                                    <Link href="/pricing" passHref>
                                        <Button color="inherit">Pricing</Button>
                                    </Link>
                                    <Link href="/about" passHref={true}>
                                        <Button component="a" color="inherit">
                                            About
                                        </Button>
                                    </Link>

                                    {/*<Link href="/pricing" passHref={true}>*/}
                                    {/*    <Button component="a" color="inherit">*/}
                                    {/*        Pricing*/}
                                    {/*    </Button>*/}
                                    {/*</Link>*/}


                                    {!auth.user && (
                                        <>
                                            <Link href="/auth/signin" passHref={true}>
                                                <Button component="a" color="inherit">
                                                    Sign in
                                                </Button>
                                            </Link>
                                            <Box component="span" ml={1}>
                                                <Link
                                                    href="/auth/signup"
                                                    passHref={true}
                                                >
                                                    <Button
                                                        component="a"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Sign up
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </>
                                    )}

                                    {auth.user && (
                                        <>
                                            <Button
                                                color="inherit"
                                                aria-label="Account"
                                                aria-controls="account-menu"
                                                aria-haspopup="true"
                                                onClick={(event) => {
                                                    handleOpenMenu(
                                                        event,
                                                        "account-menu"
                                                    );
                                                }}
                                            >
                                                Account
                                                <ExpandMoreIcon
                                                    className={classes.buttonIcon}
                                                />
                                            </Button>
                                            <Menu
                                                id="account-menu"
                                                open={
                                                    menuState &&
                                                    menuState.id === "account-menu"
                                                        ? true
                                                        : false
                                                }
                                                anchorEl={menuState && menuState.anchor}
                                                getContentAnchorEl={undefined}
                                                onClick={handleCloseMenu}
                                                onClose={handleCloseMenu}
                                                keepMounted={true}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                            >
                                                {/*<MenuItem component={Link} to="/dashboard">*/}
                                                {/*  Dashboard*/}
                                                {/*</MenuItem>*/}
                                                <Link
                                                    href="/settings/general"
                                                    passHref={true}
                                                >
                                                    <MenuItem component="a">
                                                        Settings
                                                    </MenuItem>
                                                </Link>

                                                <Link
                                                    href="/your-stories"
                                                    passHref={true}
                                                >
                                                    <MenuItem component="a">
                                                        Your Stories
                                                    </MenuItem>
                                                </Link>
                                                <Divider/>
                                                <MenuItem
                                                    onClick={(event) => {
                                                        // wait for before signing user out. This is to prevent users from abusing the free tier and signing out repeatedly
                                                        setTimeout(() => {
                                                            auth.signout();
                                                        }, 10000);
                                                    }}
                                                >
                                                    Signout
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}

                                    {/*<IconButton*/}
                                    {/*  color="inherit"*/}
                                    {/*  onClick={darkMode.toggle}*/}
                                    {/*  style={{ opacity: 0.6 }}*/}
                                    {/*>*/}
                                    {/*  {darkMode.value && <NightsStayIcon />}*/}

                                    {/*  {!darkMode.value && <WbSunnyIcon />}*/}
                                    {/*</IconButton>*/}
                                </Hidden>
                            </Grid>
                        </Grid

                        >

                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <List
                    className={classes.drawerList}
                    onClick={() => setDrawerOpen(false)}
                >
                    <Link href={home} passHref={true}>
                        <ListItem component="a" button={true}>
                            <ListItemText>Home</ListItemText>
                        </ListItem>
                    </Link>
                    <Divider/>
                    <Link href="/about" passHref={true}>
                        <ListItem component="a" button={true}>
                            <ListItemText>About</ListItemText>
                        </ListItem>
                    </Link>
                    <Link href="/pricing" passHref={true}><Link href="/pricing" passHref>
                        <Button color="inherit">Pricing</Button>
                    </Link>
                    </Link>
                    <Divider/>
                    {!auth.user && (
                        <>
                            <Link href="/auth/signin" passHref={true}>
                                <ListItem component="a" button={true}>
                                    <ListItemText>Sign in</ListItemText>
                                </ListItem>
                            </Link>
                            <Link href="/auth/signup" passHref={true}>
                                <ListItem>
                                    <Button
                                        component="a"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Sign up
                                    </Button>
                                </ListItem>
                            </Link>
                        </>
                    )}

                    {auth.user && (
                        <>
                            <Link href="/settings/general" passHref={true}>
                                <ListItem component="a" button={true}>
                                    <ListItemText>Settings</ListItemText>
                                </ListItem>
                            </Link>
                            <Link href="/your-stories" passHref={true}>
                                <ListItem component="a" button={true}>
                                    <ListItemText>Your stories</ListItemText>
                                </ListItem>
                            </Link>
                            <Divider/>
                            <ListItem
                                button={true}
                                onClick={(event) => {
                                    setTimeout(() => {
                                        auth.signout();
                                    }, 10000);
                                }}
                            >
                                <ListItemText>Sign out</ListItemText>
                            </ListItem>
                        </>
                    )}

                </List>
            </Drawer>
        </Section>
    );
}

export default Navbar;

function NewStory() {
    const [open, setOpen] = useState(false)
    const router = useRouter()
    return (<div>
        <ChooseThemeDialog
            open={open}
            onClose={(selected) => {
                setOpen(false);
                // navigate to selected theme url
                // Sci-Fi -> scifi, Date -> date, etc
                if (selected) {
                    // const normalizedString = selected.toLowerCase().replace('-', '');
                    const normalizedString = selected;
                    // router.push(`/theme/${normalizedString}`)
                    window.location.href = `/theme/${normalizedString}`;
                }
            }}
        />
        <Grid container spacing={2}>
            <Grid item>
                <Button variant={'outlined'}
                        onClick={() => {
                            setOpen(true)
                            // window.location.href = '/';
                        }}

                > <Box fontWeight={'bold'} fontFamily={'Monospace'}
                >New Story</Box></Button>
            </Grid>
            <Grid item>

                <Button variant={'outlined'}
                        onClick={() => {
                            // window.location.href = '/browse';
                            router.push('/browse')
                        }}

                > <Box fontWeight={'bold'} fontFamily={'Monospace'}
                >Browse</Box></Button>

            </Grid>


        </Grid>
    </div>)

}
