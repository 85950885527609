import {makeAutoObservable} from "mobx";
import {stripePriceValues} from "../util/prices";
import track from "./frontend_analytics";

class SessionInfo {
    paid = false;
    home = "/";
    stripeSession = null;
    freeCreditsQuota = 5;
    usedCredits = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get availableCredits() {
        return (this.freeCreditsQuota - this.usedCredits) || this.paid;
    }

    useCredit() {
        this.usedCredits += 1;
    }

    setPaid(planName, stripeSessionId) {
        this.paid = true;
        console.log("reporting conversion");
        console.log(planName);
        console.log(stripePriceValues[planName]);
        track("mytales_subscribe_success", {
                plan_name: planName,
                value: stripePriceValues[planName],
                currency: "USD",
                order_id: stripeSessionId,
                branch: process.env.BRANCH || null
            },
            'AW-805456643/fkiBCInQzY4YEIOWiYAD');
    }

    setHome(homeUrl) {
        this.home = homeUrl;
    }

    reportAttempted(planName) {
        track("mytales_subscribe_attempt", {
            plan_name: planName,
            value: stripePriceValues[planName],
            branch: process.env.BRANCH || null
        });
    }

    setStripeSession(stripeSession) {
        console.log("setting stripe session...");
        console.log(stripeSession);
        this.stripeSession = stripeSession;
    }
}

const sessionInfo = new SessionInfo();

export default sessionInfo;
