declare global {
    interface Window {
        gtag?: (...args: any) => void;
        rudderanalytics?: any;
    }
}

export default function track(eventName: string,
                              eventProperties: Record<string, any> = {},
                              googleAdsConversionId: string | null = null,
                              value: number | null = null,
                              transactionId: string | null = null) {
    try {
        console.log(`Tracking event: ${eventName} with properties: ${JSON.stringify(eventProperties)}`);
        // value must either be defined or inside the payload
        if (value) {
            eventProperties['value'] = value;
        }
        if (transactionId) {
            eventProperties['transaction_id'] = transactionId;
        }
        // our values are always in USD
        eventProperties['currency'] = 'USD';
        eventProperties['event_label'] = eventName;

        if (window.gtag && googleAdsConversionId) {
            console.log(`Google Ads Conversion ID: ${googleAdsConversionId}`);
            window.gtag('event', 'conversion', {
                'send_to': googleAdsConversionId,
                ...eventProperties
            });
        }

        if (window.rudderanalytics) {
            window.rudderanalytics.track(eventName, eventProperties);
        }

    } catch (error) {
        console.error(`Failed to track event ${eventName}:`, error);
    }


}

export function identify(userId: string, traits: Record<string, any>) {
    try {
        if (window.rudderanalytics) {
            window.rudderanalytics.identify(userId, traits);
        }
    } catch (error) {
        console.error(`Failed to identify user ${userId}:`, error);
    }
}