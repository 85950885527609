// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
// real
const NEXT_PUBLIC_STRIPE_PRICE_STARTER = "price_1NTHLUBYGlWagbhAsD1782Cu";
const NEXT_PUBLIC_STRIPE_PRICE_PRO = "price_1MnnxGBYGlWagbhArJkiYvaQ";
const NEXT_PUBLIC_STRIPE_PRICE_BUSINESS = "price_1MnnxhBYGlWagbhA7pzUzJ4o";


// dev
// const NEXT_PUBLIC_STRIPE_PRICE_STARTER="price_1N4USVBYGlWagbhAZKE805Ms";
// const NEXT_PUBLIC_STRIPE_PRICE_PRO="price_1N4USVBYGlWagbhAZKE805Ms";
// const NEXT_PUBLIC_STRIPE_PRICE_BUSINESS="price_1N4USVBYGlWagbhAZKE805Ms";



const stripePriceIds = {
    starter: NEXT_PUBLIC_STRIPE_PRICE_STARTER,
    pro: NEXT_PUBLIC_STRIPE_PRICE_PRO,
    business: NEXT_PUBLIC_STRIPE_PRICE_BUSINESS,
};

const stripePriceValues = {
    starter: 100.0, //one time
    pro: 10 * 2.5, // right now we assume 2.5 months lifetime
    business: 20 * 2.5,
};

// Get Stripe priceId

export function getStripePriceId(planName) {
    return stripePriceIds[planName];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
    return Object.keys(stripePriceIds).find(
        (key) => stripePriceIds[key] === stripePriceId
    );
}

export {stripePriceValues, stripePriceIds}
